import { Box, useTheme } from "@mui/material";
import { FeatureSection, Quote } from "@akte-mord/common";
import BackgroundImage from "../../public/homepage/quote-2-bg.jpg";
import React from "react";

export const QuoteTwo: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      altText="Hidden Game Tatort"
      bgColor={theme.palette.background.default}
      bgImage={BackgroundImage}
      bgPosition="center right"
    >
      <Box sx={{ pt: 2 }}>
        <Quote author="Philipp Steinweg, Bruder des Opfers">
          Ich kann es mir nicht erklären. Warum musste meine Schwester sterben?
        </Quote>
      </Box>
    </FeatureSection>
  );
};
