import { FeatureSection } from "@akte-mord/common";
import { Grid, Typography, useTheme } from "@mui/material";
import { TypoEffectGerechtigkeit } from "./typo-effect-gerechtigkeit";
import BackgroundImage from "../../public/homepage/feature-2-bg.jpg";
import React from "react";

export const FeatureTwo: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      altText="Tatort Krimispiel"
      bgColor={theme.palette.background.default}
      bgImage={BackgroundImage}
      bgPosition="top left"
    >
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 0, sm: 0, md: 10, xl: 14 }}
        container
        direction={{ xs: "column", md: "row" }}
        rowSpacing={{ xs: 4, sm: 6, md: 0, xl: 0 }}
      >
        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h3" sx={{ pt: 0 }}>
            Sucht den Mörder
          </Typography>

          <Typography paragraph variant="body1">
            Bei diesem Fall gibt es keine Anleitung. Stattdessen könnt ihr sofort loslegen. Ihr erhaltet
            zu Beginn die Fallakte und euren ersten Ermittlungsauftrag. Damit seid ihr auf euch allein
            gestellt.
          </Typography>

          <Typography paragraph variant="body1">
            Das Ziel ist es, allein oder im Team den Fall zu lösen. Nur indem ihr alle Unterlagen genau
            untersucht, den Hidden Clues folgt und die Informationen richtig kombiniert, kann euch das
            gelingen.
          </Typography>

          <Typography paragraph variant="body1">
            Die Lösung für jede Aufgabe könnt ihr online überprüfen. Für jede Runde könnt ihr bis zu drei
            Hinweise erhalten, solltet ihr einmal nicht weiterkommen. Jeder Hinweis bringt euch dabei
            näher an die Lösung.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ width: { xs: "100%", sm: "75%", md: "100%" } }}>
          <TypoEffectGerechtigkeit />
        </Grid>
      </Grid>
    </FeatureSection>
  );
};
