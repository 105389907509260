import { FollowUs as CommonFollowUs } from "@akte-mord/common";
import React from "react";
import BackgroundImage from "../../public/backgrounds/01.jpg";
import BackgroundImageDesktop from "../../public/typo-effects/follow-us-typo-bg-desktop.jpg";
import BackgroundImageMobile from "../../public/typo-effects/follow-us-typo-bg-mobile.jpg";

export const FollowUs: React.FC = () => (
  <CommonFollowUs
    sectionBackgroundImage={BackgroundImage}
    backgroundImageDesktop={BackgroundImageDesktop}
    backgroundImageMobile={BackgroundImageMobile}
    title="Folge uns auf Social Media"
    facebook="https://www.facebook.com/dieaktemord"
    instagram="https://www.instagram.com/aktemord"
  />
);
