import { FeatureSection, InternalInlineLink } from "@akte-mord/common";
import { Grid, Typography, useTheme } from "@mui/material";
import { TypoEffectColdCase } from "./typo-effect-cold-case";
import BackgroundImage from "../../public/homepage/feature-3-bg.jpg";
import React from "react";

export const FeatureThree: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      altText="Realistische Cold Case Kriminalfälle zum Ermitteln"
      bgColor={theme.palette.primary.main}
      bgImage={BackgroundImage}
      bgPosition="top left"
    >
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 0, sm: 0, md: 10, xl: 14 }}
        container
        direction={{ xs: "column-reverse", md: "row" }}
        rowSpacing={{ xs: 4, sm: 6, md: 0, xl: 0 }}
      >
        <Grid item xs={12} md={6} sx={{ width: { xs: "100%", sm: "75%", md: "100%" } }}>
          <TypoEffectColdCase />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" sx={{ pt: 0 }}>
            Hidden Clues
          </Typography>
          <Typography paragraph variant="body1">
            Unsere Fälle können sowohl allein als auch in Gruppen mit bis zu fünf Personen gelöst werden.
            Über 59 realistische Beweismittel und Spuren, einschließlich mehrerer Verhöre als
            Audio-Dateien, erwarten euch.
          </Typography>
          <Typography paragraph variant="body1">
            Ein paar Hinweise zur erfolgreichen Detektivarbeit:
          </Typography>

          <ul className="list">
            <li>Lest euch auf der Suche nach dem Mörder alle Unterlagen zum Fall genau durch</li>
            <li>Macht euch Notizen zum Tatort und zu den einzelnen Verdächtigen</li>
            <li>Stellt sicher, dass alle Ermittler auch wirklich alles lesen</li>
            <li>
              Überprüft eure Lösung <InternalInlineLink href="/loesung">online</InternalInlineLink>
            </li>
            <li>
              Öffnet die Umschläge für die weiteren Runden erst, wenn ihr die vorgehende Runde gelöst
              habt
            </li>
            <li>Dort erhaltet ihr auch Tipps, falls ihr in einer Runde nicht weiterkommt</li>
            <li>Die Aufklärung dürft ihr erst am Ende öffnen</li>
            <li>
              Antworten auf weitere{" "}
              <InternalInlineLink href="/hilfe">Fragen zu unseren Cold Case Fällen</InternalInlineLink>{" "}
              findet ihr hier
            </li>
          </ul>
        </Grid>
      </Grid>
    </FeatureSection>
  );
};
