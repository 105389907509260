import { Box, useTheme } from "@mui/material";
import { FeatureSection, YoutubeEmbed } from "@akte-mord/common";
import BackgroundImage from "../../public/backgrounds/01.jpg";
import React from "react";
import YotubePoster from "../../public/homepage/akte-mord-youtube-preview.jpg";

export const YoutubeTrailer: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      altText="So funktionieren unsere Fälle"
      bgColor={theme.palette.background.default}
      bgImage={BackgroundImage}
      bgImageStyles={{ opacity: 0.3 }}
      bgPosition="center left"
    >
      <Box>
        <YoutubeEmbed
          aspectRatio="2.258 / 1"
          id="zClK1YyGDhw"
          posterImage={YotubePoster}
          title="Spannende Cold Cases für Erwachsene - Such den Mörder"
        />
      </Box>
    </FeatureSection>
  );
};
