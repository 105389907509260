import { FeatureSection, InlineLink } from "@akte-mord/common";
import { Grid, Typography, useTheme } from "@mui/material";
import BackgroundImage from "../../public/homepage/feature-4-bg.jpg";
import Image from "next/image";
import KrimidinnerImage from "../../public/homepage/krimidinner-original-culinario-mortale.png";
import React from "react";

export const FeatureFour: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      altText="Von den Krimispiel-Profis von Culinario Mortale"
      bgColor={theme.palette.primary.main}
      bgImage={BackgroundImage}
      bgPosition="center right"
    >
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 0, sm: 0, md: 10, xl: 14 }}
        container
        direction={{ xs: "column", md: "row" }}
        rowSpacing={{ xs: 4, sm: 6, md: 0, xl: 0 }}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h3" sx={{ pt: 0 }}>
            Von den Machern von Culinario Mortale
          </Typography>

          <Typography paragraph variant="body1">
            Hinter AKTE MORD stehen die Macher von{" "}
            <InlineLink
              href="https://www.culinario-mortale.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              Culinario Mortale
            </InlineLink>{" "}
            – einer der erfolgreichsten deutschen Krimidinner-Reihen.
          </Typography>

          <Typography paragraph variant="body1">
            Mit unseren Fällen bieten wir Geschichten für{" "}
            <strong>Krimi-, Rätsel und Escape Room Game Fans</strong> an. Unser persönlicher Hintergrund
            liegt im Medien, Film- und Theaterbereich. Fesselnde Geschichten realistisch zu erzählen,
            haben wir als Handwerk gelernt.
          </Typography>

          <Typography paragraph variant="body1">
            Bei der Entwicklung haben wir neben umfangreichen Recherchen mit Anwälten und Kriminalbeamten
            gesprochen, um realistische und gleichzeitig sehr intensive Fälle zu erschaffen.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: { xs: "100%", sm: "75%", md: "100%" },
            overflow: { xs: "hidden", sm: "visible" },
            img: {
              transform: { xs: "scale(1.25)", md: "scale(1.5)" },
            },
          }}
        >
          <Image
            alt="Krimidinner Culinario Mortale"
            placeholder="empty" // we need this, as the blur placeholder creates an halo-like artifact on transparent images
            src={KrimidinnerImage}
            title="Tatort Krimispiele"
            sizes="50vw"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </FeatureSection>
  );
};
