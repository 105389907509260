import { FeatureFour } from "../homepage/feature-four";
import { FeatureOne } from "../homepage/feature-one";
import { FeatureThree } from "../homepage/feature-three";
import { FeatureTwo } from "../homepage/feature-two";
import { FollowUs, Footer } from "../footer";
import { GAMES } from "../data";
import { Header } from "../header";
import { Header as HeroHeader } from "../homepage/header";
import { makeProductApi, ProductProvider } from "@akte-mord/common";
import { NextSeo, ProductJsonLd } from "next-seo";
import { ProductGrid } from "../homepage/product-grid";
import { QuoteOne } from "../homepage/quote-one";
import { QuoteTwo } from "../homepage/quote-two";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { YoutubeTrailer } from "../homepage/youtube-trailer";
import i18nConfig from "../../next-i18next.config";
import React from "react";
import type { Game, LocalImage, Product } from "@akte-mord/common";
import type { GetStaticProps } from "next";

type Props = {
  featuredGame: { game: Game; product: Product };
  games: {
    title: string;
    sku: string;
    slug: string;
    price: Product["prices"];
    image: LocalImage;
  }[];
};

const Home: React.FC<Props> = ({ featuredGame, games }) => (
  <React.Fragment>
    <NextSeo
      title="Akte Mord® – Cold Cases für Zuhause"
      description="Werdet zu echten Ermittlern, sucht den Mörder und löst ultrarealistische Cold Case Fälle."
      openGraph={{
        type: "website",
        url: "https://www.akte-mord.de",
        title: "Akte Mord® – Realistische Cold Case Fälle für Erwachsene",
        description:
          "Werdet zu echten Ermittlern, sucht den Mörder und löst ultrarealistische Cold Case Fälle.",
        images: [
          {
            url: "https://www.akte-mord.de/die-spur-der-bilder/produkt/og.jpg",
            width: 1200,
            height: 627,
            alt: "Akte Mord® – Cold Cases für Erwachsene",
          },
        ],
      }}
    />
    <ProductJsonLd
      manufacturerLogo="https://www.akte-mord.de/logo-square.jpg"
      brand="Akte Mord"
      description={featuredGame.game?.teaser.join(" ")}
      images={featuredGame.game.seoProductImages ?? []}
      manufacturerName="Bischoff, Kauffmann & Otani GbR"
      material="paper"
      productName={featuredGame.product?.name}
      releaseDate="2021-10-20T08:00:00+08:00"
      slogan="Spannende Cold Case Fälle für Zuhause."
      offers={[
        {
          price: "22.95",
          priceCurrency: "EUR",
          itemCondition: "https://schema.org/NewCondition",
          availability: "https://schema.org/InStock",
          url: `https://www.akte-mord.de/kriminalfaelle/${featuredGame.game.slug}`,
          seller: {
            name: "Bischoff, Kauffmann & Otani GbR",
          },
        },
      ]}
      gtin13={featuredGame.game?.sku}
      sku={featuredGame.game?.sku}
    />
    <Header />
    <ProductProvider product={featuredGame.game} storeProduct={featuredGame.product}>
      <HeroHeader />
    </ProductProvider>
    <main>
      <YoutubeTrailer />
      <ProductGrid games={games} />
      <FeatureOne />
      <QuoteOne />
      <FeatureTwo />
      <FeatureThree />
      <QuoteTwo />
      <FeatureFour />
    </main>
    <FollowUs />
    <Footer />
  </React.Fragment>
);

export default Home;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const productApi = makeProductApi({ baseUrl: process.env.NEXT_PUBLIC_STORE_API_URL });
  const products = await productApi.list();
  const featuredGame = GAMES[0];
  const games = GAMES.map((game) => {
    const product = products.find(({ sku }) => sku === game.sku);
    if (!product) throw new Error(`Product not found for sku ${game.sku}`);
    return {
      image: game.image,
      price: product.prices,
      sku: game.sku,
      slug: game.slug,
      title: game.title,
    };
  });
  const product = products.find(({ sku }) => sku === featuredGame.sku);
  if (!product) throw new Error(`Product with sku ${featuredGame.sku} not found.`);

  return {
    props: {
      ...(locale ? await serverSideTranslations(locale, ["c-common"], i18nConfig) : {}),
      featuredGame: {
        game: featuredGame,
        product: product,
      },
      games,
    },
  };
};
