import { Box, Grid, Typography, useTheme } from "@mui/material";
import { FeatureSection, YoutubeEmbed } from "@akte-mord/common";
import { TypoEffectMord } from "./typo-effect-mord";
import BackgroundImage from "../../public/homepage/feature-1-bg.jpg";
import React from "react";
import YotubePoster from "../../public/homepage/akte-mord-youtube-preview.jpg";

export const FeatureOne: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      altText="Cold Case Krimispiel"
      bgColor={theme.palette.background.default}
      bgImage={BackgroundImage}
      bgPosition="top center"
    >
      <Grid
        alignItems="center"
        container
        direction={{ xs: "column-reverse", md: "row" }}
        columnSpacing={{ xs: 0, sm: 0, md: 10, xl: 14 }}
        rowSpacing={{ xs: 4, sm: 6, md: 0, xl: 0 }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: "none", md: "flex" }, width: { xs: "100%", sm: "75%", md: "100%" } }}
        >
          <TypoEffectMord />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h3" sx={{ pt: 0 }}>
            Spanndende Cold Case Fälle
          </Typography>

          <Typography paragraph variant="body1">
            In unseren <strong>Kriminalfällen für Erwachsene</strong> werdet ihr zu echten Ermittlern.
            Als Teil einer Cold Case Sondereinheit untersucht ihr ungelöste Mordfälle, die ergebnislos zu
            den Akten gelegt wurden. Dazu müsst ihr tief in den Fall eintauchen, die bestehende Aktenlage
            sichten, alte Zeitungsausschnitte durchforsten und euch die Aufnahmen der damals geführten
            Vernehmungen anhören. Alle Hilfsmittel, die euch bei der Lösung des Falls helfen können, sind
            erlaubt.
          </Typography>

          <Typography paragraph variant="body1">
            Bei dieser Mischung aus Roman und Krimispiel erhaltet ihr eine realistische Fallakte: die
            „Akte Mord“. Auf dem Weg, den Täter oder die Täterin zu finden, erwarten euch drei spannende
            Aufgaben. Nach all den Jahren liegt es nun an euch, dass die Opfer endlich Gerechtigkeit
            erfahren. Sucht den Mörder und löst den Cold Case.
          </Typography>
        </Grid>
      </Grid>
    </FeatureSection>
  );
};
