import {
  AddToCartButton,
  HeroHeader,
  ProductContext,
  ProductImage,
  useAnalytics,
} from "@akte-mord/common";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import BackgroundImage from "../../public/homepage/hero-header-bg.jpg";
import Link from "next/link";
import React from "react";

export const Header: React.FC = () => {
  const { image, slug, sku, storeProduct, title } = React.useContext(ProductContext);
  const theme = useTheme();
  const trackEvent = useAnalytics();

  React.useEffect(() => {
    trackEvent("view_item", {
      currency: storeProduct.prices.currency_code,
      value: parseInt(storeProduct.prices.price) / 100,
      items: [
        {
          affiliation: "www.akte-mord.de",
          currency: storeProduct.prices.currency_code,
          item_brand: "Akte Mord",
          item_id: sku,
          item_name: title,
          quantity: 1,
        },
      ],
    });
  }, [sku, storeProduct, title, trackEvent]);

  return (
    <HeroHeader
      altText={`Cover des Cold Case Falls ${title}`}
      bgColor={theme.palette.primary.main}
      bgImage={BackgroundImage}
      bgPosition="center center"
    >
      <Stack
        alignItems={{ xs: "center", md: "flex-start" }}
        direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
        spacing={{ xs: 6, sm: 8, md: 12, xl: 16 }}
      >
        <Box sx={{ flexGrow: 1, pt: { xs: 0, md: 8 }, pb: { xs: 2, sm: 3, md: 0 } }}>
          <Typography sx={{ hyphens: "none", textAlign: { xs: "center", md: "left" } }} variant="h1">
            Realistische Kriminfalfälle für Erwachsene
          </Typography>
          <Typography sx={{ textAlign: { xs: "center", md: "left" } }} variant="body2" paragraph>
            Beweismaterialien, Hidden Clues, Verhörprotokolle! Löst realistische Kriminalfälle im Team
            von bis zu 5 Detektiven. Werdet zu echten Ermittlern, sucht den Mörder und löst den Cold
            Case.
          </Typography>
          <Typography sx={{ textAlign: { xs: "center", md: "left" } }} variant="body2" paragraph>
            Für 22,95 € inkl. MwSt. Kostenloser Versand.
          </Typography>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ sm: "center", md: "flex-start" }}
            spacing={2}
            sx={{ pt: 3 }}
          >
            <AddToCartButton
              buttonProps={{
                color: "secondary",
                sx: { width: { xs: "100%", sm: "unset" } },
                variant: "contained",
              }}
              productId={storeProduct.id}
            />

            <Button
              color="secondary"
              LinkComponent={Link}
              href={`/kriminalfaelle/${slug}`}
              variant="outlined"
            >
              Fall entdecken
            </Button>
          </Stack>
        </Box>
        <ProductImage
          {...image}
          alt={`Krimispiele für Erwachsene`}
          link={`/kriminalfaelle/${slug}`}
          title="Such den Mörder"
        />
      </Stack>
    </HeroHeader>
  );
};
