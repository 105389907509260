import { Box, useTheme } from "@mui/material";
import { FeatureSection, Quote } from "@akte-mord/common";
import BackgroundImage from "../../public/homepage/quote-1-bg.jpg";
import React from "react";

export const QuoteOne: React.FC = () => {
  const theme = useTheme();

  return (
    <FeatureSection
      altText="Krimispiele Games mit Hidden Clues"
      bgColor={theme.palette.primary.main}
      bgImage={BackgroundImage}
      bgPosition="top right"
    >
      <Box sx={{ py: { xs: 2, sm: 3, md: 2 } }}>
        <Quote author="aus den Ermittlungsakten">
          {
            "Trotz der umfang\u00adreichen Ermitt\u00adlungen konnte man bisher für den Todes\u00adfall Rebecca Steinweg ein Fremd\u00adver\u00adschulden weder zweifels\u00adfrei nach\u00adweisen noch aus\u00adschließen."
          }
        </Quote>
      </Box>
    </FeatureSection>
  );
};
